import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=881acc5a&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=881acc5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881acc5a",
  null
  
)

export default component.exports