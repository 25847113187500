var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-checkbox-group',{model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}},[_c('div',[(_vm.notificationsList.length > 0)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-card',{staticClass:"card",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{on:{"change":_vm.selectAll},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}}),_c('p',[_vm._v("تحديد الكل")])],1),_c('b-button',{staticClass:"p-0",attrs:{"variant":"transparnt"},on:{"click":_vm.deleteNotification}},[_c('unicon',{attrs:{"fill":"red","height":"18px","name":"trash-alt"}})],1)]},proxy:true}],null,false,97816994)})],1),_vm._l((_vm.notificationListData),function(notifications){return _c('b-col',{key:notifications.id,attrs:{"cols":"12"}},[_c('b-card',{staticClass:"card",staticStyle:{"margin-top":"-25px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('b-form-checkbox',{attrs:{"value":notifications.id}}),_vm._v(" "+_vm._s(notifications.title)+" ")],1),_c('div',{staticClass:"d-flex"},[(
                                        notifications.notificationType ==
                                        'Student'
                                    )?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"light-warning"}},[(
                                            notifications.info.length == 1
                                        )?_c('span',[_vm._v("من تطبيق الطالب/ة "+_vm._s(notifications.info.join(","))+" ")]):_c('span',[_vm._v("من تطبيق الطلاب "+_vm._s(notifications.info.join(","))+" ")])]):_vm._e(),(
                                        notifications.notificationType ==
                                        'SellPoint'
                                    )?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(notifications.sendToCount)+" من نقاط البيع")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.formDate(notifications.date))+" "),_c('b-button',{staticClass:"p-0",attrs:{"variant":"transparnt"}},[_c('unicon',{attrs:{"name":"calendar-alt","height":"20px","fill":"gray"}})],1)],1)],1)]},proxy:true}],null,true)},[_c('b-card-text',[_vm._v(" "+_vm._s(notifications.body)+" ")])],1)],1)}),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('ek-pagination',{attrs:{"items":_vm.notificationsList,"pageLength":5},model:{value:(_vm.notificationListData),callback:function ($$v) {_vm.notificationListData=$$v},expression:"notificationListData"}})],1)],2)],1):_c('div',{staticClass:"text-center mt-2"},[_c('h4',[_vm._v("لا يوجد اشعارات")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }